<template>
  <div>
    <div class="q-pa-md">
      <!-- 表格 -->
      <q-table :dense="$q.screen.lt.md" title="封禁列表" :rows="rows" :columns="filteredColumns" :filter="searchSteamId"
        @request="queryBanList" row-key="steam_id" v-model:pagination="pagination">
        <!-- 表格主体 -->
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="avatar_full" :props="props">
              <q-avatar rounded size="30px">
                <img :src="props.row.avatar_full" />
              </q-avatar>
            </q-td>
            <q-td key="persona_name" :props="props">
              {{ props.row.persona_name }}
            </q-td>
            <q-td key="steam_id" :props="props">
              {{ props.row.steam_id }}
            </q-td>
            <q-td key="profile_url" :props="props">
              <a :href="props.row.profile_url" target="_blank">{{
                props.row.profile_url
                  ? props.row.profile_url
                    .substring(
                      props.row.profile_url.substring(0, props.row.profile_url.lastIndexOf("/")).lastIndexOf("/")
                    )
                    .replaceAll("/", "") : ''
              }}</a>
            </q-td>
            <q-td key="ban_by" :props="props">
              {{ props.row.ban_by }}
            </q-td>
            <q-td key="create_time" :props="props">
              {{ formatDate(props.row.create_time) }}
            </q-td>
            <q-td key="ban_length" :props="props">
              {{ getBanLength(props.row.ban_length) }}
            </q-td>
            <q-td key="unban_time" :props="props">
              {{ calculateUnbanTime(props.row.create_time, props.row.ban_length) }}
            </q-td>
            <q-td key="remark" :props="props">
              {{ props.row.remark }}
            </q-td>
            <q-td key="actions" v-if="isOnManagementRoute" :props="props">
              <q-btn color="secondary" dense flat icon="delete" @click="openUnbanDialog(props.row)" />
            </q-td>
          </q-tr>
        </template>
        <!-- 表格顶部右侧 -->
        <template  v-slot:top-right>
          <q-input borderless dense debounce="300" v-model="searchSteamId" placeholder="查找">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          <q-btn v-if="isOnManagementRoute" label="添加封禁" @click="openBanDialog" color="primary" class="q-ml-sm" />
        </template>
      </q-table>

      <!-- 添加封禁对话框 -->
      <q-dialog v-model="banDialog" persistent>
        <q-card style="min-width: 400px">
          <q-card-section>
            <div class="text-h6">添加封禁</div>
          </q-card-section>

          <q-form @submit.prevent="submitBan">
            <q-card-section>
              <q-input v-model="banDetails.steamId" label="Steam ID" placeholder="请输入 Steam ID"
                :rules="[val => !!val || '必填']" />
              <q-input v-model="banDetails.reason" label="封禁原因" placeholder="请输入封禁原因" type="textarea"
                :rules="[val => !!val || '必填']" />

              <q-radio v-model="banDetails.banType" val="permanent" label="永久" />
              <q-radio v-model="banDetails.banType" val="limited" label="选择解封时间" />

              <div v-if="banDetails.banType === 'limited'">
                <q-input v-model="banDetails.unbanDate" type="date" label="选择解封日期" :rules="[val => !!val || '请选择解封日期']"
                  style="margin-top: 12px;" />
                <q-input v-model="banDetails.unbanTime" type="time" format24h label="选择解封时间"
                  :rules="[val => !!val || '请选择解封时间']" style="margin-top: 12px;" />
              </div>
            </q-card-section>

            <q-card-actions align="right">
              <q-btn flat label="取消" @click="closeBanDialog" />
              <q-btn flat label="确认" type="submit" color="primary" />
            </q-card-actions>
          </q-form>
        </q-card>
      </q-dialog>

      <!-- 解封确认对话框 -->
      <q-dialog v-model="unbanDialog" persistent>
        <q-card style="min-width: 400px">
          <q-card-section>
            <div class="text-h6">确认解封</div>
            <p>确定要解封该用户吗？</p>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn flat label="取消" @click="closeUnbanDialog" />
            <q-btn flat label="确认" color="primary" @click="confirmUnban" />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import { loadBans, addBan, deleteBan } from "@/api/ban";

export default {
  name: "BanManagement",
  data() {
    return {
      pagination: {
        sortBy: "create_time",
        descending: true,
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 0
      },
      columns: [
        {
          name: "avatar_full",
          required: true,
          label: "头像",
          align: "left",
          field: "avatar_full",
        },
        {
          name: "persona_name",
          required: true,
          label: "昵称",
          align: "left",
          field: "persona_name",
        },
        {
          name: "steam_id",
          required: true,
          label: "SteamId",
          align: "left",
          field: "steam_id",
        },
        {
          name: "profile_url",
          required: true,
          label: "Steam资料页",
          align: "left",
          field: "profile_url",
        },
        {
          name: "ban_by",
          required: true,
          label: "操作人",
          align: "left",
          field: "ban_by",
        },
        {
          name: "create_time",
          required: true,
          label: "封禁时间",
          align: "left",
          field: "create_time",
        },
        {
          name: "ban_length",
          required: true,
          label: "封禁时长",
          align: "left",
          field: "ban_length",
        },
        {
          name: "unban_time",
          required: true,
          label: "解封时间",
          align: "left",
          field: "unban_time",
        },
        {
          name: "remark",
          required: true,
          label: "封禁原因",
          align: "left",
          field: "remark",
        },
        {
          name: "actions",
          label: "操作",
          align: "center",
        },
      ],
      rows: [],
      searchSteamId: "",
      banDialog: false,
      unbanDialog: false,
      currentBan: null, // 用于存储当前选择要解除封禁的行数据
      banDetails: {
        steamId: "",
        reason: "",
        banType: "permanent", // 默认永久封禁
        unbanDate: "", // 选择的解封日期
        unbanTime: "", // 选择的解封时间
        banLength: -1,
      },
    };
  },
  created() {
    this.queryBanList({
      pagination: this.pagination,
      filter: undefined
    });
  },
  computed: {
    isOnManagementRoute() {
      return this.$route.path === '/management';
    },
    filteredColumns() {
      if (this.isOnManagementRoute) {
        return this.columns;
      }
      // 如果不是 /management 路由，则过滤掉操作列
      return this.columns.filter(col => col.name !== 'actions');
    }
  },
  methods: {
    queryBanList(props) {
      const { page, rowsPerPage } = props.pagination;
      const steamId = props.filter;
      loadBans(steamId, page, rowsPerPage).then((res) => {
        this.pagination.rowsNumber = res.data.extend.total; // 设置总页数
        this.rows = res.data.extend.records
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
      });
    },
    openBanDialog() {
      this.banDialog = true;
    },
    closeBanDialog() {
      this.banDialog = false;
    },
    openUnbanDialog(row) {
      this.currentBan = row;
      this.unbanDialog = true;
    },
    closeUnbanDialog() {
      this.unbanDialog = false;
    },
    confirmUnban() {
      if (this.currentBan) {
        deleteBan(this.currentBan.id)
          .then((res) => {
            if (res.status === 200) {
              this.queryBanList({
                pagination: this.pagination,
                filter: this.searchSteamId
              });
              this.unbanDialog = false;
              this.$q.notify({
                type: "positive",
                message: "用户解封成功！",
              });
            } else {
              this.$q.notify({
                type: "negative",
                message: "解封失败，请重试。",
              });
            }
          })
          .catch((error) => {
            this.$q.notify({
              type: "negative",
              message: `操作失败：${error.message}`,
            });
          });
      }
    },
    submitBan() {
      if (this.banDetails.banType === "limited") {
        const unbanDateTime = new Date(
          `${this.banDetails.unbanDate}T${this.banDetails.unbanTime}`
        );
        const now = new Date();
        this.banDetails.banLength = Math.round(
          (unbanDateTime - now) / (1000 * 60)
        );

        if (this.banDetails.banLength < 0) {
          alert("请选择未来的解封时间");
          return;
        }
      } else {
        this.banDetails.banLength = -1;
      }
      addBan(this.banDetails).then((res) => {
        res.status === 200 && this.queryBanList({
          pagination: this.pagination,
          filter: this.searchSteamId
        });
      });
      // 重置表单后关闭对话框
      this.resetBanDetails();
      this.banDialog = false;
    },
    resetBanDetails() {
      this.banDetails = {
        steamId: "",
        reason: "",
        banType: "permanent", // 重置为默认值
        unbanDate: "", // 重置为默认值
        unbanTime: "", // 重置为默认值
      };
    },
    getBanLength(val) {
      return val === -1 ? "永久封禁" : Math.round(val / 60) + "小时";
    },
    calculateUnbanTime(createTime, banLength) {
      if (banLength === -1) {
        return "永久封禁";
      }
      const unbanDate = new Date(createTime * 1000 + banLength * 60 * 1000);
      return unbanDate.toLocaleString();
    },
    formatDate(val) {
      return new Date(val * 1000).toLocaleString();
    },
  },
};
</script>

<style scoped></style>
