import request from '@/router/axios'


export function loadBans(steamId, page, rowsPerPage) {
    return request({
        url: '/ban/list',
        method: 'get',
        params: {"steamId":steamId, "pageNo": page, "pageSize": rowsPerPage}
    })
}

export function banRank() {
    return request({
        url: '/ban/rank',
        method: 'get'
    })
}

export function addBan(ban) {
    return request({
        url: '/ban',
        method: 'post',
        data: ban
    })
}

export function deleteBan(id) {
    return request({
        url: `/ban/${id}`,
        method: 'delete'
    })
}